import React from 'react'
import { useState, useEffect } from "react";
import "./BackToUp.css"
import { Link } from 'react-scroll';
function BackToUp() {

    const [backToTopButton, setBackToTopButton] = useState(false);


    useEffect(() => {
      window.addEventListener("scroll", () =>{
        if(window.scrollY > 300) {
          setBackToTopButton(true)
         } else {
          setBackToTopButton(false)
        }
    })
    }, [])

    const scrollUp = () => {

        window.scrollTo({
        top: 0,
        behavior: "smooth"
        })
        
        }

  return (
    <div>
    {backToTopButton && (
<a className='arrowscroll' 
onClick={scrollUp}
><i class="fa-solid fa-arrow-up scrolcir"></i></a>
    )}
<a href='https://wa.me/message/CMHMUW5ETYI6G1' >
<i style={{
fontSize: "45px",
zIndex: "10",
color: "white",
}} class="fa-brands fa-whatsapp whatsappIc"></i>
</a>
{/* <a href='' >
<i style={{
fontSize: "42px",
zIndex: "10",
color: "white",
}} class="fa-brands fa-facebook-messenger messengerIc"></i>
</a> */}
<Link 
     activeClass="active" 
     to="Form"
     spy={true} 
     smooth={true} 
     offset={0} 
     duration={500}>
<button className='fixedbottum' >
 احصل على استشارتك المجانية
   </button>
 </Link>
    </div>
  )
}

export default BackToUp