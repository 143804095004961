import React from 'react'
import { useState, useEffect, useRef } from 'react';
import "./MainComp.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link,} from 'react-scroll';
import AOS from 'aos';
import "aos/dist/aos.css"
function MainComp() {
  useEffect(()=>{
    AOS.init();
}, [])
  const logosSlideRef = useRef(null);

  useEffect(() => {
    // Clone the .logos-slide element
    const originalElement = logosSlideRef.current;
    const clonedElement = originalElement.cloneNode(true);

    // Append the cloned element to .logos
    document.querySelector('.logos').appendChild(clonedElement);
  },[]);

  
const [formState, setformState] = useState({})


const changeHandler = (event) =>{
  setformState({...formState, [event.target.name] : event.target.value });
};

const submitHandler = (e) => {
  e.preventDefault()
  const config={
    SecureToken : "7e67cfae-134a-4f51-a1ac-a9547642ec27",
    To : "blumark180@gmail.com",
    From : "Blumark180@gmail.com",
    Subject : formState.subject,
    Body : `Name: ${formState.name} <br/> Email: ${formState.email} <br/> Phone: ${formState.phone} <br/> <br/> <br/> Message from blue mark website: <br/> ${formState.message} `
  
  }
if (window.Email) {
  
  window.Email.send(config).then(() => alert("تم الارسال"))
}
}
  return (
    <div  dir='rtl' >
      <div className='bodyImg'>
        
      </div>
      <div className='container'>
        <img className='creativeImg' width={"80%"} src="\images\New folder[1]\600[1]\22.png" alt=""/>
        <div className='row Sec1'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 Sec1Text'
data-aos="fade-left"
data-aos-offset="200"
data-aos-delay="100"
data-aos-duration="700"
data-aos-easing="ease-in-quad">
<h1><span>حلمك </span> <span>يتحقق</span> <span>معنا..</span></h1>
<h4>نسعى جاهدين لتجاوز توقعاتك من خلال توفير حلول تسويقية مصممة خصيصًا ليست فعالة فحسب ، <br/>  بل تعكس أيضًا هوية علامتك التجارية الفريدة ، مما يتيح لك التميز في سوق مزدحم وتحقيق أهداف عملك بسهولة.<br/>
<br/>ميز علامتك التجارية ..

<br/>

واطلب خدمات بلومارك مجاناً لمدة أسبوع .! <br/>
 ‏استغل فرصة العرض المجاني الآن..  <br/>

مع بلومارك نعمل على تزويد عملائنا بخدمات متكاملة لتلبية احتياجاتهم التقنية والتسويقية والتجارية وتطوير الأعمال..

</h4>
<a href='https://drive.google.com/file/u/0/d/11-zjWn8UjIqqRVWKH3BbILGq-5YEQ8lw/view?pli=1'>
<button hre className='learnButton' >
 عروض خطط الأسعار  
   </button>
 </a>
</div>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12' 
    data-aos="fade-up"
     data-aos-anchor-placement="top-bottom"
     data-aos-offset="200"
     data-aos-delay="100"
     data-aos-duration="900"
     data-aos-easing="ease-in-sine">
<img width={"100%"}  src="\images\New folder[1]\600[1]\16 copy.png" alt=""/> 
</div>
</div>
<br/>
<br/>
<img width={"30%"} style={{marginRight: "38%"}} src="https://techatok.com/wp-content/uploads/2023/03/Dots-min copy.png" alt=""/>
<div className='row Sec2'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 Sec1Text'
data-aos="fade-left"
data-aos-offset="200"
data-aos-delay="100"
data-aos-duration="700"
data-aos-easing="ease-in-quad">
<h1>
من نحن</h1>
<h4>نحن في بلو مارك ملتزمون بمساعدة الاعمال في المملكة العربية السعودية على تحقيق أهدافها والسعي الى تسريع التحول الرقمي . بصفتنا شركة ذات نظرة مستقبلية ، <br/> فإننا نتماشى مع رؤية المملكة 2030 للتنويع الاقتصادي والابتكار والاستدامة. يعمل فريقنا من المسوقين والمصممين والمطورين ذوي الخبرة يداً بيد لإنشاء حلول مبتكرة وفعالة تساعد عملائنا على الوصول إلى جمهورهم المستهدف وتحقيق أهداف أعمالهم. <br/> نحن نؤمن بقوة الاستراتيجيات المبنية على البيانات والتفكير الإبداعي والتحسين المستمر لتحقيق نتائج استثنائية.</h4>
</div>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'
    data-aos="fade-up"
    data-aos-anchor-placement="top-bottom"
    data-aos-offset="200"
    data-aos-delay="100"
    data-aos-duration="900"
    data-aos-easing="ease-in-sine">
<img width={"100%"} src="\images\New folder[1]\600[1]\15 copy.png" alt=""/> 
</div>
</div>
<div className='futimgDiv'>
  <div className='container '>
<div className='row'>
  <div className='futDiv col-xl-6 col-lg-6 col-md-12 col-sm-12' 
data-aos="fade-left"
data-aos-offset="250"
data-aos-delay="100"
data-aos-duration="700"
data-aos-easing="ease-in-quad">
  <h3 >كيفية العمل</h3>
  <h1>ما هي مميزات بلومارك ؟</h1>
  <br/>
  <br/>

  <div class="elementor-element elementor-element-26d77034 elementor-widget elementor-widget-eael-feature-list" data-id="26d77034" data-element_type="widget" data-widget_type="eael-feature-list.default">
				<div class="elementor-widget-container">
					<div class="-icon-position-right -tablet-icon-position-left -mobile-icon-position-right">
			<ul id="eael-feature-list-26d77034" class="eael-feature-list-items circle stacked connector-type-classic">
			                <li class="eael-feature-list-item elementor-repeater-item-1ed6926">
                                            <span class="connector" style={{left: "calc(100% - 40px)", right: 0}}></span>
                      
                    
						<div class="eael-feature-list-icon-box">
							<div class="eael-feature-list-icon-inner">

								<span class="eael-feature-list-icon fl-icon-0">

		<img decoding="async" width={"35px"}  src="https://techatok.com/wp-content/uploads/2023/03/start-up-1-4.png" class="eael-feature-list-img" alt="start up 1 4"/>								</span>
							</div>
						</div>
						<div class="eael-feature-list-content-box">
							<h3 class="eael-feature-list-title">التسويق الإعلاني</h3>
						<p class="eael-feature-list-content">سنسمح لك بالتخطيط وإنشاء جميع الاستراتيجيات الممكنة لتسويق منتجاتك وخدماتك بشكل خلاق للعملاء وزيادة العملاء المحتملين.

</p>
						</div>

					</li>
				                <li class="eael-feature-list-item elementor-repeater-item-d57bc8c">
                                            <span class="connector" style={{left: "calc(100% - 40px)", right: 0}}></span>
                    
                    
						<div class="eael-feature-list-icon-box">
							<div class="eael-feature-list-icon-inner">

								<span class="eael-feature-list-icon fl-icon-1">

		<img decoding="async" width={"35px"}  src="https://techatok.com/wp-content/uploads/2023/03/start-up-1-3.png" class="eael-feature-list-img" alt="start up 1 3"/>								</span>
							</div>
						</div>
						<div class="eael-feature-list-content-box">
							<h3 class="eael-feature-list-title">تطوير الشبكة</h3>
						<p class="eael-feature-list-content">سنوفر لك جميع تسهيلات تطوير الويب لمساعدتك في إنشاء موقع ويب محسن واحترافي وعالي التحويل.

</p>
						</div>

					</li>
				                <li class="eael-feature-list-item elementor-repeater-item-0a6cd58">
                                            <span class="connector" style={{left: "calc(100% - 40px)", right: 0}}></span>

                    
						<div class="eael-feature-list-icon-box">
							<div class="eael-feature-list-icon-inner">

								<span class="eael-feature-list-icon fl-icon-2">

		<img decoding="async" width={"35px"}  src="https://techatok.com/wp-content/uploads/2023/03/start-up-1-1.png" class="eael-feature-list-img" alt="start up 1 1"/>								</span>
							</div>
						</div>
						<div class="eael-feature-list-content-box">
							<h3 class="eael-feature-list-title">تطوير التطبيقات</h3>
						<p class="eael-feature-list-content">احصل على موارد مفيدة فورية لتحويل موقع الويب الخاص بك وعملك إلى تطبيق متميز يستجيب بشكل كامل على أي جهاز.

</p>
						</div>

					</li>
				                <li class="eael-feature-list-item elementor-repeater-item-d715433">
                                     
                      
                    
						<div class="eael-feature-list-icon-box">
							<div class="eael-feature-list-icon-inner">

								<span class="eael-feature-list-icon fl-icon-3">

		<img decoding="async" width={"35px"}  src="https://techatok.com/wp-content/uploads/2023/03/start-up-1.png" class="eael-feature-list-img" alt="start up 1"/>								</span>
							</div>
						</div>
						<div class="eael-feature-list-content-box">
							<h3 class="eael-feature-list-title">تحسين محرك البحث</h3>
						<p class="eael-feature-list-content">
كفريق مختص في التسويق الرقمي فإننا ندرك أهمية أن يكون لديك خطة تحسين محركات البحث (SEO). فيما يلي بعض خطط تحسين محركات البحث (SEO)
</p>
						</div>

					</li>
							</ul>
		</div>
				</div>
				</div>
  </div>
  <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'
      data-aos="fade-up"
      data-aos-anchor-placement="top-bottom"
      data-aos-offset="250"
      data-aos-delay="100"
      data-aos-duration="900"
      data-aos-easing="ease-in-sine">
<img width={"100%"} src="\images\New folder[1]\600[1]\8 copy.png" alt=""/> 
</div>
</div>
</div>
</div>
<div className='titleDiv'
    data-aos="fade-up"
    data-aos-anchor-placement="top-bottom"
    data-aos-offset="300"
    data-aos-delay="100"
    data-aos-duration="900"
    data-aos-easing="ease-in-sine">
<h3>نبذه عن خدماتنا</h3>
<h1>خدمات بلومارك</h1>
</div>
<div className='row'>
  <div     className='ServDiv mb-5 col-xl-3 col-lg-3 col-md-6 col-sm-12'>
 <img width={"100%"} src="\images\New folder[1]\600[1]\10 copy.png" alt=""/>

<div className='trtrrr'>
  <h4>تصميم الهوية البصرية</h4>
<p>الهوية البصرية مهمة لأي مشروع لانها تساعد على بناء علامة تجارية واضحة وفريدة، تقدم لكم بلومارك خطط عديدة تناسب حجم مشاريعكم واتساقها في العديد من المنافذ.</p> 
</div>

 </div>
  <div     className='ServDiv col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
 <img width={"100%"} height={"280px"} src="\images\New folder[1]\600[1]\9 copy.png" alt=""/>
 
<div className='trtrrr'>
 
 <h4>المتاجر الالكترونية</h4>
<p>ضع بصمتك من خلال متجر الكتروني يقدم منتجاتك بشكل يخدمك في خطتك التسويقية وزيادة ارباحك والتوسع في مشروعك ،بمتجر مميز يضعك خطوة للأمام متفوقاً على منافسيك !!</p> 
</div>
   </div>
  <div     className='ServDiv col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
 <img width={"100%"} src="\images\New folder[1]\600[1]\12 copy.png" alt=""/>

<div className='trtrrr'>
  <h4>انشاء تطبيقات الكترونية</h4>
<p>تعد تطبيقات الهاتف أداة أساسية للشركات للتواصل مع عملائها وتعزيز وجودهم عبر الإنترنت. نقدم لكم بلومارك مجموعة شاملة من خطط بناء تطبيقات الهاتف المحمول التي تلبي الاحتياجات المتنوعة للعملاء </p>
</div>
  </div>
  <div     className='ServDiv col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
 <img width={"100%"} src="\images\New folder[1]\600[1]\11 555555.png" alt=""/>

<div className='trtrrr'>
 <h4>المواقع التعريفية</h4>
 <p>كشركة مختصة في تقنية المعلومات في المملكة العربية السعودية، نحن ملتزمون بتوفير خدمات تصميم مواقع احترافية عالية الجودة تناسب احتياجات عملائنا من كل الفئات، نتفهم أن لكل مشروع احتياجات مختلفة من ميزانيات وأهداف لذلك نعمل على العديد من الخطط التي تهتم لمجموعة مختلفة من شرائح العملاء</p>
</div>
 </div>
  <div     className='ServDiv col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
 <img width={"100%"} src="\images\New folder[1]\600[1]\13 copy.png" alt=""/>
  
 <div className='trtrrr'>
 <h4>ادارة الحملات الاعلانية</h4>
<p>كفريق مختص في التسويق الرقمي فإننا ندرك أهمية وجود حملات تسويق رقمية جيدة التخطيط والتنفيذ لمساعدة الشركات على تحقيق أهدافها. من هذا المنطلق نقدم خطط التسويق الرقمي التي تلبي احتياجات العملاء المختلفة</p>
 </div>
  
  </div>
  <div     className='ServDiv col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
 <img width={"100%"} src="\images\New folder[1]\600[1]\14 copy.png" alt=""/>
 
  <div className='trtrrr'>
 <h4>ادارة مواقع التواصل الاجتماعي</h4>
<p>كفريق مختص في التسويق الرقمي فإننا ندرك أهمية إدارة وسائل التواصل الاجتماعي لتلبية مستويات مختلفة من العملاء. تم تصميم خطط إدارة وسائل التواصل الاجتماعي لمساعدة الشركات على بناء تواجدها عبر الإنترنت </p>
  </div>
  
  </div>
  <div     className='ServDiv col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
 <img width={"100%"} src="\images\New folder[1]\600[1]\3 copy.png" alt=""/>
  
  <div className='trtrrr'>
 <h4>تحسين محركات البحث</h4>
<p>نقدم خدمة تحسين محرك بحث شاملة وفريدة من نوعها مصممة لمساعدة الشركات على تعزيز وجودها على الإنترنت وتنمية قاعدة عملائها. يستخدم فريقنا من خبراء تحسين محركات البحث (SEO) ذوي الخبرة أحدث التقنيات والاستراتيجيات لضمان احتلال موقع الويب الخاص بك مرتبة عالية في صفحات نتائج محرك البحث</p>
  </div>
   
  </div>
  <div     className='ServDiv col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3'>
 <img width={"100%"} src="\images\New folder[1]\600[1]\12 copy.png" alt=""/>
   
  <div className='trtrrr'>
 <h4> ادارة المتاجر الالكترونية</h4>
<p>بلومارك بفريقها المتمكن، تقدم خدمات إدارة المتاجر عبر الإنترنت مصممة لتناسب احتياجات وميزانيات عملائنا . مع تزويدهم أيضًا بالأدوات والموارد اللازمة لإدارة متاجرهم على الإنترنت بشكل فعال</p>
  </div>
   
  </div>
</div>
      </div>

<div className='MarktingSec'>
  <h1 style={{fontSize: "80px"}}>التسويق الرقمي</h1>
  <br/>
  <br/>
  <div className='row MainMRK'>
     <div     data-aos="fade-up"
     data-aos-anchor-placement="top-bottom"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="500"
     data-aos-easing="ease-in-sine" className='ServDiv row col-xl-4 col-lg-4 col-md-4 col-sm-12'>
<div className='col-xl-3 col-lg-3 col-md-3 col-sm-3'>
<img  src="https://trendmedia-mea.com/wp-content/uploads/2021/09/icon-06-05.png" alt=""/>
</div>
<div className=' booold col-xl-8 col-lg-8 col-md-8 col-sm-8'>
<h6>
ادارة الحملات التسويقية</h6>
<h4><span>إدارة</span> وسائل التواصل الاجتماعي الفيسبوك ، تويتر ، الانستغرام</h4>
</div>
   </div>
     <div     data-aos="fade-up"
     data-aos-anchor-placement="top-bottom"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="500"
     data-aos-easing="ease-in-sine" className='ServDiv row col-xl-4 col-lg-4 col-md-4 col-sm-12'>
<div className='col-xl-3 col-lg-3 col-md-3 col-sm-3'>
<img  src="https://trendmedia-mea.com/wp-content/uploads/2021/09/icon-06-04.png" alt=""/>
</div>
<div className=' booold col-xl-8 col-lg-8 col-md-8 col-sm-8'>
<h6>
إعلانات جوجل</h6>
<h4><span>قم بزيادة </span>
 ظهور محرك البحث الخاص بك واحصل على نتائج فورية مع خدمات إدارة PPC من Trend Media.</h4>
</div>
   </div>
     <div     data-aos="fade-up"
     data-aos-anchor-placement="top-bottom"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="500"
     data-aos-easing="ease-in-sine" className='ServDiv row col-xl-4 col-lg-4 col-md-4 col-sm-12'>
<div className='col-xl-3 col-lg-3 col-md-3 col-sm-3'>
<img  src="https://trendmedia-mea.com/wp-content/uploads/2021/09/icon-06-01.png" alt=""/>
</div>
<div className=' booold col-xl-8 col-lg-8 col-md-8 col-sm-8'>
<h6>
تطوير البرمجيات</h6>
<h4><span>تطوير </span> 
 تطبيقات ومواقع ويب احترافية للجوال لأعمالك</h4>
</div>
   </div>
     <div     data-aos="fade-up"
     data-aos-anchor-placement="top-bottom"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="500"
     data-aos-easing="ease-in-sine" className='ServDiv row col-xl-4 col-lg-4 col-md-4 col-sm-12'>
<div className='col-xl-3 col-lg-3 col-md-3 col-sm-3'>
<img  src="https://trendmedia-mea.com/wp-content/uploads/2021/09/icon-06-06-1.png" alt=""/>
</div>
<div className=' booold col-xl-8 col-lg-8 col-md-8 col-sm-8'>
<h6>
المتاجر الالكترونية</h6>
<h4><span>تطوير </span> المتاجر عبر الإنترنت وإدارتها من خلال تكامل بوابة الدفع</h4>
</div>
   </div>
     <div     data-aos="fade-up"
     data-aos-anchor-placement="top-bottom"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="500"
     data-aos-easing="ease-in-sine" className='ServDiv row col-xl-4 col-lg-4 col-md-4 col-sm-12'>
<div className='col-xl-3 col-lg-3 col-md-3 col-sm-3'>
<img  src="https://trendmedia-mea.com/wp-content/uploads/2021/09/crea-07.png" alt=""/>
</div>
<div className=' booold col-xl-8 col-lg-8 col-md-8 col-sm-8'>
<h6>العلامة التجارية</h6>
<h4><span>نحن نطور </span>إستراتيجيات العلامة التجارية الإبداعية لجذب الجمهور المستهدف.</h4>
</div>
   </div>
     <div     data-aos="fade-up"
     data-aos-anchor-placement="top-bottom"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="500"
     data-aos-easing="ease-in-sine" className='ServDiv row col-xl-4 col-lg-4 col-md-4 col-sm-12'>
<div className='col-xl-3 col-lg-3 col-md-3 col-sm-3'>
<img  src="https://trendmedia-mea.com/wp-content/uploads/2021/09/icon-06-03.png" alt=""/>
</div>
<div className=' booold col-xl-8 col-lg-8 col-md-8 col-sm-8'>
<h6>
تحسين نتائج البحث</h6>
<h4><span>ارفع  </span>من تصنيفات البحث واكتشف المزيد عبر الإنترنت</h4>
</div>
   </div>
  </div>
</div>

<div className='clintDiv'
    data-aos="fade-right"
    data-aos-offset="400"
    data-aos-delay="130"
    data-aos-duration="900"
    data-aos-easing="ease-in-sine">
  <h1 style={{ textAlign: "center"}}>اراء عملاء بلومارك</h1>
  <br/>
  <br/>
  <br/>
  <Swiper navigation={true}
     modules={[Navigation]}
     slidesPerView={3}
     loop={true}
     slidesPerGroup={3}
     speed={700}
     style={{ marginBottom: "20px", marginTop: "0px" }}

     breakpoints={{
      300: { slidesPerView: 1, slidesPerGroup: 1},
      450: { slidesPerView: 1, slidesPerGroup: 1 },
      720: { slidesPerView: 2, slidesPerGroup: 2 },
      900: { slidesPerView: 2, slidesPerGroup: 2 },
      1200: { slidesPerView: 3, },
    }}
  

    className="mySwiper col-xl-12 col-lg-12 col-md-12 col-sm-12" >
        <SwiperSlide >
         <div className='silderTextDiv'>
          <h4>محمد ابو نوره</h4>
          <br/>
          <p>للامانه متعاونين جدا جدا وعروضهم قوية، سويت عندهم متجر وحملة اعلانية والجماعة ما قصروا وسلموني شغل احترافي والفريق متعاون تحس انك وسط بيتك </p>
       
       <div className='Starsdiv'>
       <i class="fa-solid fa-star-half-stroke"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
        <span>4.5</span>
       </div>
         </div>
       
        </SwiperSlide>
        <SwiperSlide >
         <div className='silderTextDiv'>
        <h4>ثامر العنزي</h4>
          <br/>
         <p>سويت عندهم متجر دروبشيبينق واضافولي المنتجات فيه ودرسوها واعطوني معاه ايميل مجاني ويا ربي لك الحمد بفضل الله ثم هم متجري للحين شغال ويعطيني دخل</p> 
          <div className='Starsdiv'>
          <i class="fa-regular fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
        <span>4.0</span>
       </div>
         </div>
       
        </SwiperSlide>
        <SwiperSlide >
         <div className='silderTextDiv'>
          <h4>منصة Visión</h4>
          <br/>
    <p>خدمات التسويق الإلكتروني التي قدمتها ساعدتنا في زيادة مبيعاتنا بشكل كبير. نحن ممتنون لجهودكم.
</p>
                 <div className='Starsdiv'>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
        <span>5.0</span>
       </div>
         </div>
       
        </SwiperSlide>
        <SwiperSlide >
         <div className='silderTextDiv'>
         <h4>روان الحربي</h4>
          <br/>
          <p>اللي يفرقهم عن غيرهم انهم يركزون على ادق التفاصيل ويكونو معك من الاول للاخير، تعلمت اشياء كثير منهم ما تعلمتها من دورة دافعه فيها دم قلبي</p>
                    <div className='Starsdiv'>
       <i class="fa-solid fa-star-half-stroke"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
        <span>4.5</span>
       </div>
         </div>
       
        </SwiperSlide>
        <SwiperSlide >
         <div className='silderTextDiv'>
          <h4>محمد ابو نوره</h4>
          <br/>
          <p>للامانه متعاونين جدا جدا وعروضهم قوية، سويت عندهم متجر وحملة اعلانية والجماعة ما قصروا وسلموني شغل احترافي والفريق متعاون تحس انك وسط بيتك </p>
                <div className='Starsdiv'>
       <i class="fa-solid fa-star-half-stroke"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
        <span>4.5</span>
       </div>
         </div>
       
        </SwiperSlide>
        <SwiperSlide >
         <div className='silderTextDiv'>
        <h4>نايف العتيبي</h4>
          <br/>
   <p>لا يمكننا أن نشكركم بما فيه الكفاية على تطوير الأعمال واقتراحاتكم الذكية. أنتم مميزون</p>
                   <div className='Starsdiv'>
       <i class="fa-solid fa-star-half-stroke"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
        <span>4.5</span>
       </div>
         </div>
       
        </SwiperSlide>
        <SwiperSlide >
         <div className='silderTextDiv'>
       <h4>روان العنزي</h4>
          <br/>
      <p>من خلال تصميم المتجر الإلكتروني لنا، نجحنا في توسيع عملنا على الإنترنت بشكل كبير. نحن سعداء جدًا
</p>
             <div className='Starsdiv'>
       <i class="fa-solid fa-star-half-stroke"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
        <span>4.5</span>
       </div>
         </div>
       
        </SwiperSlide>
        <SwiperSlide >
         <div className='silderTextDiv'>
         <h4>متجر ربحانة</h4>
          <br/>
      <p>
لقد كنتم دائمًا متواجدين لدعمنا والاستجابة لاحتياجاتنا في التسويق الإلكتروني. تقديري لجهودكم
</p>
                        <div className='Starsdiv'>
       <i class="fa-solid fa-star-half-stroke"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
       <i class="fa-solid fa-star"></i>
        <span>4.5</span>
       </div>
         </div>
       
        </SwiperSlide>
      
           </Swiper>
</div>
<div className='clintLogo container'>
  <h1 style={{ textAlign: "center"}}> عملاء بلومارك</h1>
  <br/>
  <br/>
  <br/>
<div class="logos">
  <div class="logos-slide" ref={logosSlideRef}>
    <div>
              <img src="\images\New folder[1]\logos\3C351F80-5BF3-4561-A48A-8438E5777528.png" />
        <img src="\images\New folder[1]\logos\9FFF6ADD-3148-4E31-A8EB-8DB55C5B1D01.png" />
        <img src="\images\New folder[1]\logos\677737C6-243A-4249-938D-030BA9A2D8C1.png" />
        <img src="\images\New folder[1]\logos\684562C0-AE26-43F8-876C-5D6D09E80BF3.png" />
        <img src="\images\New folder[1]\logos\2548768C-BD2F-4B53-B5F5-B1A9A8618F73.png" />
        <img src="\images\New folder[1]\logos\B9092484-C42A-464E-9188-B5AE2FB0104E.png" />
        <img src="\images\New folder[1]\logos\C0FB4B06-8627-497B-AE7C-B29EB29AA0AC.png" />
        <img src="\images\New folder[1]\logos\C19E10D5-5AD2-416E-8A87-3EAFF922D032.png" />
        <img src="\images\New folder[1]\logos\DA7EABB5-1736-4FE4-AE7D-38FE9B851BEC.png" />
   
    </div>
    <div>
         <img src="\images\New folder[1]\logos\3C351F80-5BF3-4561-A48A-8438E5777528.png" />
        <img src="\images\New folder[1]\logos\9FFF6ADD-3148-4E31-A8EB-8DB55C5B1D01.png" />
        <img src="\images\New folder[1]\logos\677737C6-243A-4249-938D-030BA9A2D8C1.png" />
        <img src="\images\New folder[1]\logos\684562C0-AE26-43F8-876C-5D6D09E80BF3.png" />
        <img src="\images\New folder[1]\logos\2548768C-BD2F-4B53-B5F5-B1A9A8618F73.png" />
        <img src="\images\New folder[1]\logos\B9092484-C42A-464E-9188-B5AE2FB0104E.png" />
        <img src="\images\New folder[1]\logos\C0FB4B06-8627-497B-AE7C-B29EB29AA0AC.png" />
        <img src="\images\New folder[1]\logos\C19E10D5-5AD2-416E-8A87-3EAFF922D032.png" />
        <img src="\images\New folder[1]\logos\DA7EABB5-1736-4FE4-AE7D-38FE9B851BEC.png" />
   
    </div>

   
      </div>
 

    </div>
</div>
<div dir="rtl" className="mainBlock" id='Form'>
<h1 style={{ textAlign: "center"}}>تواصل معنا </h1>
<br/>
  <br/>
  <br/>
<div className="row block-9 justify-content-center mb-5">
<div data-aos="fade-right"
     data-aos-anchor-placement="top-bottom"
     data-aos-offset="300"
     data-aos-delay="100"
     data-aos-duration="700"
     data-aos-easing="linear" className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
<h2  style={{fontSize: "50px"}} className="text-center mb-5">لا تتردد في سؤالنا<br/>عن أي شيء</h2>
        <div className="secondlargdivof5  move2">
<div className="locasssts">
  <div className='test'>
    <span>
        <i className="fa-solid fa-location-dot"></i>
    </span>
<p>مكة المكرمة - النسيم</p>
  </div>

</div>
<div className="locasssts">
  <div className='test'>
    <span>
        <i class="fa-brands fa-whatsapp"></i> 
    </span>
<p>
 0507006849
</p>
  </div>

</div>
<div className="locasssts">
  <div className='test'>
    <span>
            <i className="fa-solid fa-envelope envoo"></i>
    </span>
   <p>contact@blumark24.com</p>
  </div>

</div>
</div>
</div>
<div class="container col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div class="text">
      اتصل بنا
      </div>
      <form onSubmit={submitHandler}>
         <div class="form-row">
            <div class="input-data">
               <input name='name' type="text" value={formState.name || ''}  required onChange={changeHandler}/>
               <div class="underline"></div>
               <label for=""> الاسم</label>
            </div>
            <div class="input-data">
               <input type="phone" name='phone' value={formState.phone || ''}  required onChange={changeHandler} />
               <div class="underline"></div>
               <label for="">رقم الجوال</label>
            </div>
         </div>
         <div class="form-row">
            <div class="input-data">
               <input name='email' type="email" value={formState.email || ''} required onChange={changeHandler}/>
               <div class="underline"></div>
               <label for="">البريد الالكتروني</label>
            </div>
            <div class="input-data">
               <input type="text" name='subject' value={formState.subject || ''}  required onChange={changeHandler} />
               <div class="underline"></div>
               <label for="">الموضوع</label>
            </div>
         </div>
         <div class="form-row">
         <div class="input-data textarea">
            <textarea rows="8" cols="80" name='message' value={formState.message || ''}  onChange={changeHandler} ></textarea>
            <br />
            <div class="underline"></div>
            <label for="">اكتب رسالتك</label>
            <br />
            <div class="form-row submit-btn">
               <div class="input-data">
                  <div class="inner"></div>
                  <input type="submit" value="ارسل الان"/>
               </div>
            </div>
            </div>
            </div>
      </form>
      </div>
</div>
</div>
    </div>
  )
}

export default MainComp