import React from 'react'
import { useEffect } from 'react';
import "./ECommerce.css"
import AOS from 'aos';
import "aos/dist/aos.css"
function AdsPage() {
    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div className='container' dir='rtl'>
    <div className='ECommerceTextDiv' data-aos="fade-up"
     data-aos-offset="100"
     data-aos-delay="100"
     data-aos-duration="700"
     data-aos-easing="ease-in-sine">
     <h1>ادارة الحملات الاعلانية</h1>
 <p>كفريق مختص في التسويق الرقمي فإننا ندرك أهمية وجود حملات تسويق رقمية جيدة التخطيط والتنفيذ لمساعدة الشركات على تحقيق أهدافها.<br/> من هذا المنطلق نقدم خطط التسويق الرقمي التي تلبي احتياجات العملاء المختلفة ، من الشركات الناشئة إلى الشركات الكبيرة. فيما يلي خطط حملات التسويق الرقمي الخاصة بنا:</p>
         </div>
    <div className='row Sec1'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 Sec1Text' data-aos="fade-left"
     data-aos-offset="200"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<h3>ما مميزاتنا</h3>
<h4>نحن نفخر بأنفسنا لخدماتنا المتطورة لإدارة الحملات عبر الإنترنت. من خلال نهجنا الشامل ، نتخلص من عناء التسويق عبر الإنترنت ، مما يسمح لك بالتركيز على عملك بينما نعتني بالباقي. تم تصميم خدمة إدارة الحملات عبر الإنترنت الخاصة بنا لزيادة جهودك التسويقية من خلال الاستفادة من أحدث الأدوات والتقنيات. سنعمل معك لتطوير استراتيجية مخصصة تلبي احتياجاتك وأهدافك الفريدة. من إنشاء نص إعلان مقنع إلى تحسين حملاتك لتحقيق أقصى عائد استثمار ، لدينا الخبرة والتجربة لمساعدتك على تحقيق النجاح. سيقوم فريق الخبراء لدينا بمراقبة حملاتك على مدار الساعة ، وإجراء التعديلات والتحسينات حسب الحاجة لضمان وصول رسالتك إلى الجمهور المناسب. نقدم أيضًا تقارير وتحليلات مفصلة ، حتى تتمكن من تتبع تقدمك واتخاذ قرارات مستنيرة بشأن إستراتيجيتك التسويقية.</h4>
</div>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12' data-aos="fade-right"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<img width={"100%"}  src="\images\New folder[1]\600[1]\8 copy.png" alt=""/> 
</div>
</div>
<div className='PlaneDiv'>
<div className='row'>
 <div className='PlanSmallDiv col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الأساسية</h1>
    <br/>
    <br/>
<h4>هذه الخدمة مثالية للشركات الناشئة والشركات الصغيرة التي بدأت للتو رحلة التسويق الرقمي. تشمل الخدمات التالية:
- إدارة حملات تسويقية لوسائل التواصل الاجتماعي (حتى منصتين)
- تصميم وتطوير الموقع الأساسي
- التسويق عبر البريد الإلكتروني (حتى 500 مشترك)
- إنشاء المحتوى (4 منشورات مدونة في الشهر)</h4>
    <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>المتوسطة</h1>
    <br/>
    <br/>
 <h4>تم تصميم الخطة المتوسطة الخاصة بنا للشركات التي تتطلع إلى توسيع نطاق وصولها وزيادة قاعدة عملائها. تشمل الخدمات التالية: - إدارة حملات تسويقية لوسائل التواصل الاجتماعي (حتى 3 منصات) - تصميم وتطوير مواقع متقدم - تحسين محرك البحث (SEO) - التسويق عبر البريد الإلكتروني (حتى 1000 مشترك) - إنشاء المحتوى (8 منشورات مدونة في الشهر)
</h4>
      <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv spicial col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الاستثنائية</h1>
    <br/>
    <br/>
 <h4>تم تصميم الخطة الاستثنائية الخاصة بنا للشركات والمؤسسات الكبيرة التي تتطلب استراتيجية تسويق رقمي شاملة. تشمل الخدمات التالية: - إدارة حملات تسويقية لوسائل التواصل الاجتماعي (حتى 5 منصات) - تصميم وتطوير مواقع متقدم - تحسين محرك البحث (SEO) - إعلانات الدفع بالنقرة (PPC) - التسويق عبر البريد الإلكتروني (حتى 5000 مشترك) - إنشاء المحتوى (16 مشاركة مدونة في الشهر) - تسويق بالفيديو - التسويق المؤثر
</h4>
       <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>المتقدمة</h1>
    <br/>
    <br/>
<h4>تعتبر الخدمة الاحترافية مثالية للشركات الراسخة التي تتطلع إلى نقل جهود التسويق الرقمي إلى المستوى التالي. تشمل الخدمات التالية: - إدارة حملات تسويقية لوسائل التواصل الاجتماعي (حتى 4 منصات) - تصميم وتطوير مواقع متقدم - تحسين محرك البحث (SEO) - إعلانات الدفع بالنقرة (PPC) - التسويق عبر البريد الإلكتروني (حتى 2000 مشترك) - إنشاء المحتوى (12 مدونة في الشهر)</h4>
       <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
</div>
</div>
    </div>
  )
}

export default AdsPage