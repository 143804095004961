import React from 'react'
import NavBar from '../NavBar/NavBar'
import Slider from '../Slider/Slider'
import MainComp from '../MainCom/MainComp'
import Footer from '../Footer/Footer'

function Homecomp() {
  return (
    <>
    <Slider/>
    <MainComp/>
    </>
  )
}

export default Homecomp