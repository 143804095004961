import './App.css';
import Contact from './Component/Contact/Contact';
import Footer from './Component/Footer/Footer';
import Homecomp from './Component/Homecomp/Homecomp';
import NavBar from './Component/NavBar/NavBar';
import { BrowserRouter, Routes , Route ,Link} from 'react-router-dom'
import ECommerce from './Component/Services/ECommerce';
import AboutUs from './Component/AboutUs/AboutUs';
import VisualIdentity from './Component/Services/VisualIdentity';
import Consultation from './Component/Services/consultation';
import Applections from './Component/Services/applections';
import Privacy from './Component/Services/Privacy';
import BackToUp from './Component/BackToUp/BackToUp';
import AdsPage from './Component/Services/AdsPage';
import SocialPage from './Component/Services/SocialPage';
import PortfolioPage from './Component/Services/PortfolioPage';
import SeoPage from './Component/Services/SeoPage';
import MangeEcoPage from './Component/Services/MangeEcoPage';
function App() {
  return (
   <BrowserRouter>
   <NavBar/>
<Routes>
<Route path='/' element={<Homecomp />} />
<Route path='/Contact' element={<Contact />} />
<Route path='/ECommerce' element={<ECommerce />} />
<Route path='/AboutUs' element={<AboutUs />} />
<Route path='/VisualIdentity' element={<VisualIdentity />} />
<Route path='/Consultation' element={<Consultation />} />
<Route path='/Applections' element={<Applections />} />
<Route path='/Privacy' element={<Privacy />} />
<Route path='/AdsPage' element={<AdsPage />} />
<Route path='/SocialPage' element={<SocialPage />} />
<Route path='/PortfolioPage' element={<PortfolioPage />} />
<Route path='/SeoPage' element={<SeoPage />} />
<Route path='/MangeEcoPage' element={<MangeEcoPage />} />
</Routes>
<Footer/>
<BackToUp/>
     </BrowserRouter>
  );
}

export default App;
