import React from 'react'
import "./ECommerce.css"
import { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css"
import "./ECommerce.css"
function Applections() {
    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div className='container' dir='rtl'>
    <div className='ECommerceTextDiv' data-aos="fade-up"
     data-aos-offset="100"
     data-aos-delay="100"
     data-aos-duration="700"
     data-aos-easing="ease-in-sine">
     <h1>انشاء التطبيقات الالكترونية</h1>
   <p>تعد تطبيقات الهاتف أداة أساسية للشركات للتواصل مع عملائها وتعزيز وجودهم عبر الإنترنت.<br/> نقدم لكم بلومارك مجموعة شاملة من خطط بناء تطبيقات الهاتف المحمول التي تلبي الاحتياجات المتنوعة للعملاء في المملكة العربية السعودية.<br/> تم تصميم حزمنا لتزويد الشركات بتطبيقات جوّال عالية الجودة وسهلة الاستخدام تتوافق مع هوية علامتها التجارية وأهدافها.</p>
       </div>
    <div className='row Sec1'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 Sec1Text' data-aos="fade-left"
     data-aos-offset="200"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<h3>ما مميزاتنا</h3>
<h4>خدمة بناء تطبيقات الهاتف المحمول لدينا لا مثيل لها في السوق. نحن نقدم نهجًا شاملاً لبناء تطبيقات الهاتف المحمول المخصصة المصممة خصيصًا لتلبية الاحتياجات الفريدة لعملائنا. يعمل فريقنا من المطورين والمصممين ذوي الخبرة عن كثب معك لفهم متطلبات عملك وأهدافك وأهدافك لإنشاء تطبيق وظيفي بالكامل ومذهل بصريًا. نحن نتفهم أن كل عمل يختلف عن الآخر ، ولهذا السبب نقدم نهجًا مخصصًا لتطوير تطبيقات الهاتف المحمول. يستغرق فريقنا الوقت الكافي لفهم جمهورك المستهدف واتجاهات الصناعة وتحليل المنافسين لإنشاء تطبيق يبرز في السوق. نضمن أيضًا أن يكون التطبيق سهل الاستخدام وبديهيًا وسهل التنقل ، مما يوفر تجربة سلسة لعملائك. تتضمن خدمة إنشاء تطبيقات الهاتف المحمول الخاصة بنا أيضًا الصيانة والدعم المستمرين ، مما يضمن تحديث تطبيقك دائمًا ويعمل بسلاسة. نستخدم أحدث التقنيات والأدوات للتأكد من أن تطبيقك مُحسن للأداء والسرعة والأمان.</h4>
</div>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12' data-aos="fade-right"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<img width={"100%"}  src="\images\New folder[1]\600[1]\12 copy.png" alt=""/> 
</div>
</div>
<div className='PlaneDiv'>
<div className='row'>
 <div className='PlanSmallDiv col-xl-4 col-lg-3 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الأساسية</h1>
    <br/>
    <br/>
 <h4>تعتبر الخطة الأساسية الخاصة بنا مثالية للشركات الصغيرة والشركات الناشئة التي تبحث عن تطبيقات الهاتف الفعال من حيث التكلفة. تتضمن هذه الخدمة تطبيقًا أساسيًا للهاتف يصل إلى خمس صفحات ، وتصميمًا بسيطًا ، ووظائف مثل نماذج الاتصال ، وربط وسائل التواصل الاجتماعي ، و إرسال الإشعارات. الخطة الأساسية مناسبة للشركات التي تتطلع إلى إنشاء وجود أساسي عبر الإنترنت وجذب المزيد من العملاء.</h4>
     <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv spicial col-xl-4 col-lg-3 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الاستثنائية</h1>
    <br/>
    <br/>
  <h4>تم تصميم مجموعتنا الاحترافية للشركات التي تتطلب تطبيقًا مخصصًا بالكامل للهاتف مع ميزات ووظائف متقدمة. تتضمن هذه الخطة تطبيقًا للهاتف يصل إلى خمس عشرة صفحة وتصميمًا مخصصًا ووظائف متقدمة مثل الواقع المعزز وروبوتات الدردشة الذكية والتحليلات المتقدمة. تعتبر الخطة المتقدمة مثالية للشركات التي تتطلع إلى وضع نفسها كقادة في الصناعة وتقديم تجربة عملاء فريدة ومبتكرة.</h4>
      <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv col-xl-4 col-lg-3 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>المتوسطة</h1>
    <br/>
    <br/>
 <h4>تم تصميم الخطة للشركات التي تتطلب تطبيقًا أكثر تطوراً للهاتف مع ميزات ووظائف متقدمة. تتضمن هذه الخطة تطبيقًا يصل إلى عشر صفحات ، وميزات تصميم متقدمة ، ووظائف ربط التجارة الإلكترونية ، وأنظمة الحجز عبر الإنترنت ، والخدمات المستندة إلى الموقع. تعتبر الخطة المتوسطة مثالية للشركات التي تتطلع إلى تحسين تجربة العملاء وتقديم خدمة أكثر شمولاً عبر الإنترنت.</h4>
     <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
</div>
</div>
    </div>
  )
}

export default Applections