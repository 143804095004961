import React from 'react'
import { useEffect } from 'react';
import "./ECommerce.css"
import AOS from 'aos';
import "aos/dist/aos.css"
function MangeEcoPage() {
    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div className='container' dir='rtl'>
    <div className='ECommerceTextDiv' data-aos="fade-up"
     data-aos-offset="100"
     data-aos-delay="100"
     data-aos-duration="700"
     data-aos-easing="ease-in-sine">
 <h1>ادارة المتاجر الالكترونية</h1>
<p>بلومارك بفريقها المتمكن، تقدم خدمات إدارة المتاجر عبر الإنترنت مصممة لتناسب احتياجات وميزانيات عملائنا .<br/> مع تزويدهم أيضًا بالأدوات والموارد اللازمة لإدارة متاجرهم على الإنترنت بشكل فعال.</p>
         </div>
    <div className='row Sec1'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 Sec1Text' data-aos="fade-left"
     data-aos-offset="200"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<h3>ما مميزاتنا</h3>
<h4>نقدم خدمة إدارة المتاجر الإلكترونية الفريدة من نوعها ، والمصممة خصيصًا للشركات التي تتطلع إلى النمو وتوسيع نطاق عمليات التجارة الإلكترونية الخاصة بها. يقدم فريقنا من المسوقين ذوي الخبرة وخبراء التجارة الإلكترونية حلاً شاملاً يغطي كل جانب من جوانب إدارة متجر على الإنترنت ، من قوائم المنتجات إلى تلبية الطلبات. نحن نتفهم أن تشغيل متجر على الإنترنت قد يكون أمرًا مربكًا ، ولهذا السبب تم تصميم خدمتنا للتخلص من المتاعب المتعلقة بإدارة متجرك. نحن نهتم بجميع الجوانب الفنية لمتجرك ، بما في ذلك صيانة الموقع والاستضافة والأمان ، حتى تتمكن من التركيز على تنمية عملك. يوفر فريقنا أيضًا إرشادات الخبراء حول اختيار المنتج واستراتيجيات التسعير والحملات التسويقية لمساعدتك في الوصول إلى جمهورك المستهدف وزيادة المبيعات. نحن نستخدم أدوات تحليلات متقدمة لتتبع أداء متجرك ، ونوفر لك رؤى قيمة لاتخاذ قرارات مستنيرة بشأن عملك.</h4>
</div>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12' data-aos="fade-right"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<img width={"100%"}  src="\images\New folder[1]\600[1]\19.png" alt=""/> 
</div>
</div>
<div className='MangeEcoDiv'>
<h1 style={{textAlign: "center"}}>ماذا نقدم</h1>
<br/>
<br/>
<ol className='container'>
<li>– تصميم وتطوير موقع الويب: تتضمن هذه الخدمة تصميم وتطوير وإطلاق متجر على الإنترنت. و تشمل سمات مخصصة وتصنيف المنتجات وتوظيف عربة التسوق وربط بوابة الدفع.</li>
<li>– إدارة المنتج: تتضمن هذه الخدمة قائمة ، ووصف ، وصور ، ومراجعات المنتجات. وتشمل إدارة المخزون وإدارة التسعير وترويج المنتجات.</li>
<li>– إدارة الطلبات: تتضمن هذه الخدمة معالجة الطلبات وتتبعها ، والفواتير ، وإدارة الشحن. وتشمل أيضًا دعم خدمة العملاء وإدارة المرتجعات.</li>
<li>– التسويق والإعلان: تتضمن هذه الخدمة تحسين محركات البحث (SEO) ، وإعلانات الدفع لكل نقرة (PPC) ، والتسويق عبر وسائل التواصل الاجتماعي ، والتسويق عبر البريد الإلكتروني ، وتسويق المحتوى. وتشمل أيضًا تحليلات وتقارير لقياس نجاح هذه الجهود.</li>
<li>– الأمان والصيانة: تتضمن هذه الخدمة إجراءات أمان موقع الويب وتحديثات البرامج المنتظمة والنسخ الاحتياطية لموقع الويب. وتشمل أيضًا الدعم الفني واستكشاف الأخطاء وإصلاحها.</li>
<li>– التخصيص والربط: تتضمن هذه الخدمة القدرة على تخصيص خدمات الطرف الثالث ودمجها ، مثل برامج المحاسبة ، وبرامج إدارة علاقات العملاء (CRM) ، ومقدمي خدمات الشحن
</li>
</ol>
</div>
    </div>
  )
}

export default MangeEcoPage