import React from 'react'
import { useEffect } from 'react';
import "./ECommerce.css"
import AOS from 'aos';
import "aos/dist/aos.css"
function SocialPage() {
    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div className='container' dir='rtl'>
    <div className='ECommerceTextDiv' data-aos="fade-up"
     data-aos-offset="100"
     data-aos-delay="100"
     data-aos-duration="700"
     data-aos-easing="ease-in-sine">
   <h1>ادارة مواقع التواصل الاجتماعي</h1>
 <p>كفريق مختص في التسويق الرقمي فإننا ندرك أهمية إدارة وسائل التواصل الاجتماعي لتلبية مستويات مختلفة من العملاء. <br/> تم تصميم خطط إدارة وسائل التواصل الاجتماعي لمساعدة الشركات على بناء تواجدها عبر الإنترنت ، وزيادة المشاركة</p>
         </div>
    <div className='row Sec1'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 Sec1Text' data-aos="fade-left"
     data-aos-offset="200"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<h3>ما مميزاتنا</h3>
<h4>حن نمثل رحلة جميلة ومتناغمة في عالم إدارة الحملات عبر الإنترنت، وهذا بما يتيح للجميع الاستفادة من خدماتنا. نحن نقدم خدمات جميلة ومتناغمة تستهدف شريحة واسعة من الجمهور، مما يجعل تجربتك معنا شاملة وملهمة.

نحن نعتبر عملنا مزيجًا من الجمال والتنسيق، ونسعى جاهدين لجعل استراتيجياتنا متناغمة مع احتياجات الجميع. نهدف إلى تقديم رسائل تسويقية تلامس قلوب وعقول مختلفة.

فريق الخبراء لدينا يعمل بجد لضمان أن حملاتنا تستهدف وتلهم الجميع. نحن نقدم تقارير متناغمة وتحليلات مفصلة تساعدك على فهم تأثير حملاتك على الجمهور المستهدف.

بنا، ستجد تجربة جميلة ومتناغمة تستهدف الجميع. نحن هنا نحن نمثل رحلة جميلة ومتناغمة في عالم إدارة الحملات عبر الإنترنت، وهذا بما يتيح للجميع الاستفادة من خدماتنا. نحن نقدم خدمات جميلة ومتناغمة تستهدف شريحة واسعة من الجمهور، مما يجعل تجربتك معنا شاملة وملهمة.

نحن نعتبر عملنا مزيجًا من الجمال والتنسيق، ونسعى جاهدين لجعل استراتيجياتنا متناغمة مع احتياجات الجميع. نهدف إلى تقديم رسائل تسويقية تلامس قلوب وعقول مختلفة.
</h4>
</div>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12' data-aos="fade-right"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<img width={"100%"}  src="\images\New folder[1]\600[1]\55888.png" alt=""/> 
</div>
</div>
    </div>
  )
}

export default SocialPage