import React from 'react'
import './Slider.css'
import "./styles.scss";
import * as ReactDOM from 'react-dom';
import classNames from 'classnames';
function Slider() {
  class CitiesSlider extends React.Component {
    constructor(props) {
      super(props);

      this.IMAGE_PARTS = 4;

      this.changeTO = null;
      this.AUTOCHANGE_TIME = 4000;

      this.state = { activeSlide: -1, prevSlide: -1, sliderReady: false };
    }

    componentWillUnmount() {
      window.clearTimeout(this.changeTO);
    }

    componentDidMount() {
      this.runAutochangeTO();
      setTimeout(() => {
        this.setState({ activeSlide: 0, sliderReady: true });
      }, 0);
    }

    runAutochangeTO() {
      this.changeTO = setTimeout(() => {
        this.changeSlides(1);
        this.runAutochangeTO();
      }, this.AUTOCHANGE_TIME);
    }

    changeSlides(change) {
      window.clearTimeout(this.changeTO);
      const { length } = this.props.slides;
      const prevSlide = this.state.activeSlide;
      let activeSlide = prevSlide + change;
      if (activeSlide < 0)
        activeSlide = length - 1;
      if (activeSlide >= length)
        activeSlide = 0;
      this.setState({ activeSlide, prevSlide });
    }

    render() {
      const { activeSlide, prevSlide, sliderReady } = this.state;
      return (
        <div  className={classNames('slider', { 's--ready': sliderReady })}>
          <div className="slider__slides">
            {this.props.slides.map((slide, index) => (
              <div
                className={classNames('slider__slide', { 's--active': activeSlide === index, 's--prev': prevSlide === index })}
                key={slide.city}
              >
                <div dir='rtl' className="slider__slide-content">
                  <h1 className="slider__slide-subheading">{slide.country}
                  <br/>
                   <span>{slide.city}</span></h1>
                
                </div>
                <div className="slider__slide-parts">
                  {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
                    <div className="slider__slide-part" key={i}>
                      <div className="slider__slide-part-inner" style={{ backgroundImage: `url( ${slide.img})` }} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="slider__control" onClick={() => this.changeSlides(-1)} />
          <div className="slider__control slider__control--right" onClick={() => this.changeSlides(1)} />
        </div>
      );
    }
  }

  const slides = [
     {
      city: 'الخاص بك',
      country: 'الجزء الرقمي',
      img: 'https://trendmedia-mea.com/wp-content/uploads/2021/09/slide10-%D8%B9%D8%B1%D8%A8%D9%8A-1.jpg',
    },
    {
      city: 'حول العالم',
      country: 'بلومارك تنتشر',
      img: 'https://trendmedia-mea.com/wp-content/uploads/2021/09/slide200-%D8%B9%D8%B1%D8%A8%D9%8A-1.jpg',
    },
    // {
    //   // city: 'حول العالم',
    //   // country: 'تنتشر',
    //   img: 'https://i.ibb.co/cxydhhd/2.png',
    // },

  ];


  return (

    <div id='sapp'>
<CitiesSlider slides={slides} />
    </div>

  );
}

export default Slider