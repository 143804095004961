import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <div>
      <footer dir="rtl">
        <div className="content">
          <div dir="ltr" className="top">
            <div className="logo-details">
              <a className="navbar-brand">
                <img
                  width={"50%"}
                  src="\images\WhatsApp_Image_2023-09-28_at_11.43.21_879238df-removebg-preview.png"
                  alt=""
                />
              </a>
            </div>
            <div className="media-icons">
              <a href="https://www.facebook.com/profile.php?id=61550555918065&mibextid=LQQJ4d">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="https://twitter.com/Blumark24">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://instagram.com/blumark24?utm_source=qr">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.tiktok.com/@blumark24?_t=8oSCDKnImnf&_r=1">
                <i class="fa-brands fa-tiktok"></i>
              </a>
              <a href="https://youtube.com/@Blumark24?si=wwxHncdR5U-fSHIS">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>

          <div className="link-boxes row">
            <ul className="box col-xl-5 col-lg-4 col-md-6 col-sm-12 ">
              <li className="link_name">About Us</li>
              <p>
                بلومارك شركة سعودية برؤية طموحة بُنيت بسواعد شباب قادرين على
                تبديل <br /> المستحيل إلى واقع والعقبات إلى صعوبات في طي النسيان
                ، بلومارك سعودية <br /> شغفها العالمية
              </p>
            </ul>
            <ul className="box col-xl-2 col-lg-2 col-md-6 col-sm-12">
              <li className="link_name">الصفحات</li>
              <li>
                <a href="/">الرئيسية</a>
              </li>
              <li>
                <a href="/AboutUs">من نحن</a>
              </li>
              <li>
                <a href="/">حدماتنا</a>
              </li>
            </ul>
            <ul className="box col-xl-2 col-lg-2 col-md-6 col-sm-12">
              <li className="link_name">روابط مفيدة</li>
              <li>
                <a href="/Contact">اتصل بنا</a>
              </li>
              <li>
                <a href="Privacy">سياسة الخصوصية و الاستخدام</a>
              </li>
            </ul>
            <div className="secondlargdivof5 col-xl-2 col-lg-2 col-md-6 col-sm-12">
              <div>
                <h1 className="link_name">معلومات التواصل</h1>
              </div>
              <div className="locasssts">
                <div className="test">
                  <span>
                    <i className="fa-solid fa-location-dot"></i>
                  </span>
                  <p>مكة المكرمة - النسيم</p>
                </div>
              </div>
              <div className="locasssts">
                <div className="test">
                  <span>
                    <i class="fa-brands fa-whatsapp"></i>
                  </span>
                  <p>966507006849</p>
                </div>
              </div>
              <div className="locasssts">
                <div className="test">
                  <span>
                    <i className="fa-solid fa-envelope envoo"></i>
                  </span>
                  <p>contact@blumark24.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-details">
          <div className="bottom_text">
            <span className="copyright_text">
              Copyright © 2023{" "}
              <a href="https://api.whatsapp.com/send?phone=201101848363">
                Blumark Sa
              </a>
              All rights reserved
            </span>
            <span className="policy_terms">
              <a href="/Privacy">Privacy policy</a>
              <a href="/Privacy">Terms & condition</a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer