import React from 'react'
import "./NavBar.css"
import { BrowserRouter, Routes , Route ,Link} from 'react-router-dom'
function NavBar() {
  window.addEventListener("scroll", function(){
    var nav = document.querySelector("nav");
    nav.classList.toggle("sticky", window.scrollY > 700)
  } );
  return (
    <>
    <nav dir='rtl' className="navbar navbar-expand-lg nav" id='nav'>
      <div className="container">
    
           <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active p-2">
              <Link className="nav-link"  to="/">الرئيسية <span className="sr-only">(current)</span></Link>
            </li>        
              <li className="nav-item p-2">
            <Link className="nav-link"   to="/AboutUs">من نحن</Link>
            </li>
            <li className="nav-item p-2 dropdown">
              <Link className="nav-link dropdown-toggle" data-bs-toggle="dropdown"  role="button" aria-expanded="false" id='navbarDropdownMenuLink'  to="/About">خدماتنا </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li><Link className="dropdown-item" to="/VisualIdentity">تصميم الهوية البصرية</Link></li>
            <li><Link className="dropdown-item" to="/ECommerce">انشاء المتاجر الالكترونية</Link></li>
            <li><Link className="dropdown-item" to="/MangeEcoPage">ادارة المتاجر الالكترونية</Link></li>
            <li><Link className="dropdown-item" to="/Applections">انشاء تطبيقات الكترونية</Link></li>
            <li><Link className="dropdown-item" to="/AdsPage">ادارة الحملات الاعلانية</Link></li>
            <li><Link className="dropdown-item" to="/SocialPage">ادارة مواقع التواصل الاجتماعي</Link></li>
            <li><Link className="dropdown-item" to="/PortfolioPage">انشاء المواقع الالكترونية</Link></li>
            <li><Link className="dropdown-item" to="/SeoPage">تحسين محركات البحث</Link></li>
          </ul>
            </li>
            <li className="nav-item p-2">
              <Link className="nav-link"    to="/Contact">تواصل معنا</Link>
            </li>
            <li className="consultation">
              <Link className="nav-link"    to="/Consultation">احصل علي استشارة مجانية</Link>
            </li>
            
          </ul>
        </div>
        <Link to='/' className="" ><img height={"80px"} style={{marginTop: "10px",}} src="\images\WhatsApp_Image_2023-09-28_at_11.43.21_879238df-removebg-preview.png" alt=""/></Link>
      </div>
      </nav>
    </>
  )
}

export default NavBar
