import React from 'react'
import { useEffect } from 'react';
import "./ECommerce.css"
import AOS from 'aos';
import "aos/dist/aos.css"
function SeoPage() {
    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div className='container' dir='rtl'>
    <div className='ECommerceTextDiv' data-aos="fade-up"
     data-aos-offset="100"
     data-aos-delay="100"
     data-aos-duration="700"
     data-aos-easing="ease-in-sine">
     <h1>تحسين محركات البحث</h1>
 <p>كفريق مختص في التسويق الرقمي فإننا ندرك أهمية أن يكون لديك خطة تحسين  <br/>محركات البحث (SEO). فيما يلي بعض خطط تحسين محركات البحث (SEO):</p>
          </div>
    <div className='row Sec1'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 Sec1Text' data-aos="fade-left"
     data-aos-offset="200"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<h3>ما مميزاتنا</h3>
<h4>يعمل فريقنا من المسوقين والمصممين وخبراء الأعمال ذوي الخبرة عن كثب مع كل عميل لإنشاء خطة تبني مخصصة تلبي احتياجاتهم وتحدياتهم الخاصة. نبدأ بإجراء تحليل شامل لأعمال العميل والسوق والمنافسة لتحديد مجالات الفرص والنمو. بمجرد أن يكون لدينا فهم واضح لأهداف العميل وأهدافه ، نقوم بتطوير استراتيجية تبني مخصصة تتضمن مجموعة من الخدمات ، مثل تصميم العلامات التجارية والهوية ، وأبحاث السوق ، والإعلان ، وإنشاء المحتوى ، وإدارة الوسائط الاجتماعية ، والمزيد. صُممت خدمة تبني الخاصة بنا لمساعدة الشركات في كل مرحلة من مراحل نموها ، من الشركات الناشئة في مراحلها الأولى إلى الشركات القائمة التي تتطلع إلى توسيع نطاق وصولها وحصتها في السوق. من خلال نهجنا التعاوني والتوجيه العملي ، نساعد الشركات على تحقيق نمو مستدام ، وزيادة الإيرادات ، وبناء حضور قوي للعلامة التجارية في صناعتها. إذا كنت تبحث عن شركة تسويق يمكنها أن تساعد عملك على النجاح والازدهار ، فإن خدمة تبني الأعمال الفريدة لدينا هي الحل الأمثل. اتصل بنا لمعرفة المزيد حول كيف يمكننا مساعدتك في تنمية أعمالك وتحقيق أهدافك.</h4>
</div>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12' data-aos="fade-right"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<img width={"100%"}  src="\images\New folder[1]\600[1]\3 copy.png" alt=""/> 
</div>
</div>
<div className='PlaneDiv'>
<div className='row'>
 <div className='PlanSmallDiv col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الأساسية</h1>
    <br/>
    <br/>
<h4>- بحث وتحليل الكلمات الرئيسية
- تحسين على الصفحة يصل إلى 5 صفحات
- تحسين العلامات الوصفية
- إعداد Google Analytics
- إنشاء خريطة موقع XML
- تقارير سير العمل الشهرية</h4>
    <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>المتوسطة</h1>
    <br/>
    <br/>
<h4>- بحث وتحليل الكلمات الرئيسية - تحسين على الصفحة يصل إلى 10 صفحات - تحسين العلامات الوصفية - إعداد Google Analytics والمراقبة - إنشاء خريطة موقع XML - تقارير سير العمل الشهرية - تحسين محركات البحث المحلية - إنشاء المحتوى وتحسينه</h4>
      <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv spicial col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الاستثنائية</h1>
    <br/>
    <br/>
<h4>- بالنسبة للشركات ذات الاحتياجات الفريدة ، يمكن إنشاء حزمة مخصصة لتحسين محركات البحث بناءً على أهدافها ومتطلباتها المحددة. من المهم ملاحظة أن تحسين محركات البحث عملية مستمرة وقد لا تكون النتائج فورية. ومع ذلك ، من خلال الجهود المستمرة والمراقبة ، يمكن رؤية تحسن كبير في تصنيفات محرك البحث على موقع الويب وحركة المرور.</h4>
       <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>المتقدمة</h1>
    <br/>
    <br/>
<h4>- بحث وتحليل الكلمات الرئيسية - تحسين على الصفحة حتى 20 صفحة - تحسين العلامات الوصفية - إعداد Google Analytics والمراقبة - إنشاء خريطة موقع XML - تقارير سير العمل الشهرية - تحسين محركات البحث المحلية - إنشاء المحتوى وتحسينه - بناء وصلة - تحسين وسائل التواصل الاجتماعية - تحسين "نشاطي التجاري على Google" - تحسين معدل التحويل</h4>
        <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
</div>
</div>
    </div>
  )
}

export default SeoPage