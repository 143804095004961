import React from 'react'
import { useEffect } from 'react';
import "./ECommerce.css"
import AOS from 'aos';
import "aos/dist/aos.css"
function VisualIdentity() {
    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div className='container' dir='rtl'>
    <div className='ECommerceTextDiv' data-aos="fade-up"
     data-aos-offset="100"
     data-aos-delay="100"
     data-aos-duration="700"
     data-aos-easing="ease-in-sine">
     <h1>تصميم الهوية البصرية</h1>
    <p>الهوية البصرية مهمة لأي مشروع لانها تساعد على بناء علامة تجارية واضحة وفريدة، <br/> تقدم لكم بلومارك خطط عديدة تناسب حجم مشاريعكم واتساقها في العديد من المنافذ</p>
        </div>
    <div className='row Sec1'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 Sec1Text' data-aos="fade-left"
     data-aos-offset="200"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<h3>ما مميزاتنا</h3>
<h4>نحن متخصصون في إنشاء هويات مرئية فريدة تجسد جوهر علامتك التجارية. خدمة إنشاء الهوية المرئية لدينا هي عملية شاملة تتضمن البحث والتحليل والإبداع لضمان تميز علامتك التجارية عن المنافسة. يعمل فريقنا من المصممين ذوي الخبرة عن كثب معك لفهم شخصية علامتك التجارية وقيمها والجمهور المستهدف. ثم نستخدم هذه المعلومات لإنشاء هوية مرئية تعكس قصة علامتك التجارية وتتردد صداها مع عملائك. عمليتنا تعاونية للغاية ، ونبقيك مشاركًا في كل خطوة على الطريق. من المفهوم الأولي إلى التصميم النهائي ، نعمل معك للتأكد من أن كل عنصر من عناصر هويتك المرئية مثالي. تتضمن خدمة إنشاء الهوية المرئية الخاصة بنا تصميم الشعار ولوحات الألوان والطباعة والعناصر المرئية الأخرى التي تجعل علامتك التجارية فريدة من نوعها. نقدم أيضًا إرشادات حول كيفية استخدام هويتك المرئية عبر جميع قنوات التسويق الخاصة بك لضمان الاتساق والتعرف على العلامة التجارية. نفخر بقدرتنا على إنشاء علامة تجارية فعالة ومذهلة بصريًا تميز عملائنا عن المنافسة. دعنا نساعدك في إنشاء هوية مرئية تمثل حقًا علامتك التجارية وتتردد صداها مع عملائك.</h4>
</div>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12' data-aos="fade-right"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<img width={"100%"}  src="\images\New folder[1]\600[1]\10 copy.png" alt=""/> 
</div>
</div>
<div className='PlaneDiv'>
<div className='row'>
 <div className='PlanSmallDiv col-xl-4 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الأساسية</h1>
    <br/>
    <br/>
<h4>مخصصة للمشاريع الصغيرة التي تريد بناء هويتها التجارية - تصميم شعار - اختيار تدرجات الألوان - اختيار الخطوط - تصميم بطاقة عمل</h4>
    <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv col-xl-4 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>المتوسطة</h1>
    <br/>
    <br/>
 <h4>مخصصة للمشاريع التي تتطلع لانشاء هوية بصرية شاملة. - تصميم شعار - اختيار تدرجات الألوان - اختيار الخطوط - تصميم بطاقة العمل - تصميم الأوراق الرسمية - تصميم ظروف - تصاميم لحسابات السوشل ميديا</h4>
     <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv spicial col-xl-4 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الاستثنائية</h1>
    <br/>
    <br/>
  <h4>مخصصة للمشاريع التي تتطلع لانشاء هوية بصرية متسقة وقوية. - تصميم شعار - اختيار تدرجات الألوان - اختيار الخطوط - تصميم بطاقة العمل - تصميم الأوراق الرسمية - تصميم ظروف - تصاميم لحسابات السوشل ميديا - ملف ارشادات علامتك التجارية</h4>
      <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
</div>
</div>
    </div>
  )
}

export default VisualIdentity