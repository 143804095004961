import React from 'react'
import { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css"
import "./ECommerce.css"
function ECommerce() {
    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div className='container' dir='rtl'>
    <div className='ECommerceTextDiv' data-aos="fade-up"
     data-aos-offset="100"
     data-aos-delay="100"
     data-aos-duration="700"
     data-aos-easing="ease-in-sine">
        <h1>انشاء المتاجر الالكترونية</h1>
        <p>ضع بصمتك من خلال متجر الكتروني يقدم منتجاتك بشكل يخدمك في خطتك التسويقية وزيادة ارباحك والتوسع في مشروعك ،<br/>بمتجر مميز يضعك خطوة للأمام متفوقاً على منافسيك </p>
    </div>
    <div className='row Sec1'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 Sec1Text' data-aos="fade-left"
     data-aos-offset="200"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<h3>ما مميزاتنا</h3>
<h4>مرحبًا بكم في خدمة بناء المتجر عبر الإنترنت ، حيث نساعد في تحويل حلمك بامتلاك متجر إلكتروني ناجح إلى حقيقة واقعة. تم تصميم نهجنا الفريد لبناء متاجر عبر الإنترنت لتزويدك بتجربة خالية من المتاعب ، من البداية إلى النهاية. بفضل خبرتنا في التسويق وتطوير الويب ، نقوم بإنشاء متاجر مخصصة على الإنترنت مصممة خصيصًا لعلامتك التجارية والجمهور المستهدف. يعمل فريقنا من المصممين والمطورين المهرة معًا لبناء موقع ويب مذهل لا يبدو رائعًا فحسب ، بل يعمل أيضًا بسلاسة. نحن نتفهم أن تشغيل متجر على الإنترنت يمكن أن يكون أمرًا مربكًا ، ولهذا السبب نقدم مجموعة شاملة من الخدمات لمساعدتك في إدارة متجرك بكفاءة. من إدارة المنتجات إلى تلبية الطلبات ، قمنا بتغطيتك.تم تصميم خدمة بناء المتاجر عبر الإنترنت لتكون ميسورة التكلفة ومتاحة للشركات من جميع الأحجام. نحن نعطي الأولوية لنجاحك ونعمل معك في كل خطوة على الطريق لضمان تحسين متجرك عبر الإنترنت للتحويلات وتوليد الإيرادات.</h4>
</div>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12' data-aos="fade-right"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<img width={"100%"}  src="\images\New folder[1]\600[1]\9 copy.png" alt=""/> 
</div>
</div>
<div className='PlaneDiv'>
<div className='row'>
 <div className='PlanSmallDiv col-xl-3 col-lg-3 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الأساسية</h1>
    <br/>
    <br/>
    <h4>تناسب المشاريع الصغيرة او الافراد المقبلين على عالم التجارة الالكترونية. - متجر بتصميم بسيط. - الربط مع طرق الدفع. - أساسيات تحسين الكلمات المفتاحية بالمتجر. - ادخال المنتجات مع كتابة التوصيف. - تصميم بسيط للهوية البصرية.</h4>
    <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv col-xl-3 col-lg-3 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>المتوسطة</h1>
    <br/>
    <br/>
   <h4>تناسب المشاريع التي تتطلع للظهور على العالم الرقمي. - متجر بتصميم حسب رغبة العميل. - الربط مع طرق الدفع. - تحسين الكلمات المفتاحية المتقدمة الخاصة بالمتجر. - ربط مواقع التواصل الاجتماعي - ادخال المنتجات مع كتابة التوصيف - تصميم متقدم للهوية البصرية.</h4>
    <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv spicial col-xl-3 col-lg-3 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الاستثنائية</h1>
    <br/>
    <br/>
   <h4>تناسب المشاريع الكبيرة التي تتطلع لنقل متجرها الحالي للمستوى القادم - متجر بتصميم حسب رغبة العميل. - الربط مع طرق الدفع. - تحسين محركات البحث المتقدمة الخاصة بالمتجر. - ربط مواقع التواصل الاجتماعي. - مدير حساب خاص بالمتجر. - تقارير اداء شهرية. - تحليلات سوق للمتجر. - ادخال المنتجات مع كتابة التوصيف. - تصميم متقدم للهوية البصرية. - الربط مع المصادر الخارجية.</h4>
    <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv col-xl-3 col-lg-3 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الصيانة</h1>
    <br/>
    <br/>
 <h4>مخصصة لتزويد العملاء بالدعم المستمر والصيانة للمتاجر الالكترونية. - دعم فني. - تحديثات. - تحديثات أمان. - تحسين محتوى. - اضافة منتجات. - تقارير فنية للمبيعات.</h4>
    <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
</div>
</div>
    </div>
  )
}

export default ECommerce