import React from 'react'
import { useEffect } from 'react';
import "./ECommerce.css"
import AOS from 'aos';
import "aos/dist/aos.css"
function PortfolioPage() {
    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div className='container' dir='rtl'>
    <div className='ECommerceTextDiv' data-aos="fade-up"
     data-aos-offset="100"
     data-aos-delay="100"
     data-aos-duration="700"
     data-aos-easing="ease-in-sine">
   <h1>انشاء المواقع الالكترونية</h1>
 <p>كشركة مختصة في تقنية المعلومات في المملكة العربية السعودية، نحن ملتزمون <br/> بتوفير خدمات تصميم مواقع احترافية عالية الجودة تناسب احتياجات عملائنا من كل الفئات، <br/> نتفهم أن لكل مشروع احتياجات مختلفة من ميزانيات وأهداف لذلك نعمل على العديد من الخطط التي تهتم لمجموعة مختلفة من شرائح العملاء</p>
          </div>
    <div className='row Sec1'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 Sec1Text' data-aos="fade-left"
     data-aos-offset="200"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<h3>ما مميزاتنا</h3>
<h4>يعمل فريقنا من المصممين والمطورين ذوي الخبرة عن كثب معك لفهم علامتك التجارية والجمهور المستهدف وأهداف عملك لإنشاء موقع ويب لا يبدو رائعًا فحسب ، بل يؤدي أيضًا إلى نتائج مذهلة. نستخدم أحدث التقنيات واتجاهات التصميم لضمان أن يكون موقع الويب الخاص بك حديثًا وسريع الاستجابة ومحسّنًا لمحركات البحث.</h4>
</div>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12' data-aos="fade-right"
     data-aos-offset="250"
     data-aos-delay="100"
     data-aos-duration="800"
     data-aos-easing="ease-in-sine">
<img width={"85%"}  src="\images\New folder[1]\600[1]\11 555555.png" alt=""/> 
</div>
</div>
<div className='PlaneDiv'>
<div className='row'>
 <div className='PlanSmallDiv col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الأساسية</h1>
    <br/>
    <br/>
<h4>مخصصة للمشاريع الناشئة التي تبدأ بموقع تعريفي بسيط يخدم الشريحة المستهدفة لهم
- موقع تعريفي مبسط مكون من ٥ صفحات يحاكي هويتك البصرية.
- تحسين الكلمات المفتاحية للمواضيع داخل الموقع لتحسين الظهور على محركات البحث. - ربط نماذج التواصل.
- تصميمات متوافقة مع أجهزة الجوال.
- أربع مراجعات مجانية.</h4>
    <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الأعمال</h1>
    <br/>
    <br/>
<h4>مخصصة للمشاريع المتوسطة التي تحتاج لمواقع شاملة بمميزات متقدمة - موقع تعريفي مكون من ١٠ صفحات يحاكي هويتك البصرية. - تحسين الكلمات المفتاحية المتقدمة للمواضيع داخل الموقع لتحسين الظهور على محركات البحث. - ربط مواقع التواصل الاجتماعي. - ربط المتاجر الالكترونية. - تصميمات متوافقة مع أجهزة الجوال. - عشرة مراجعات مجانية.</h4>
      <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv spicial col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>المشاريع الكبيرة</h1>
    <br/>
    <br/>
<h4>مخصصة للمشاريع الكبيرة التي تحتاج لمواقع أكثر تطورًا و وظائف متقدمة - موقع تعريفي مكون من ٢٠ صفحة تحاكي هويتك البصرية. - تحسين الكلمات المفتاحية المتقدمة للمواضيع داخل و خارج الموقع لتحسين الظهور على محركات البحث. - ربط متقدم لمواقع التواصل الاجتماعي. - تطوير وظائف متقدمة. - حلول المتاجر الالكترونية. - تصميمات متوافقة مع أجهزة الجوال. - مراجعات مجانية لا محدودة.</h4>
       <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
 <div className='PlanSmallDiv col-xl-3 col-lg-4 col-md-6 col-sm-12'>
    <h3>الخطة</h3>
    <h1>الصيانة</h1>
    <br/>
    <br/>
<h4>مخصصة لمساعدة الأعمال على ابقاء مواقعهم مؤمنة و محدثة - نسخ احتياطي للموقع. - تحديثات الأمان. - تحديثات المحتوى. - مراقبة أداء الموقع. - دعم فني. - تقارير شهرية.</h4>
       <br/>
    <a  href="/Contact">
<button className='ContactBB' >
  تواصل معنا
   </button>
 </a>
 </div>
</div>
</div>
    </div>
  )
}

export default PortfolioPage