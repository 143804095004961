import { useState, useEffect } from "react";
import "./Contact.css"
import AOS from 'aos';
import "aos/dist/aos.css"
function Contact() {
  useEffect(()=>{
    AOS.init();
}, [])

const [formState, setformState] = useState({})


const changeHandler = (event) =>{
  setformState({...formState, [event.target.name] : event.target.value });
};

const submitHandler = (event) => {
  event.preventDefault();
  const config={
   SecureToken : "7e67cfae-134a-4f51-a1ac-a9547642ec27",
   To : "blumark180@gmail.com",
   From : "Blumark180@gmail.com",
    Subject : formState.subject,
    Body : `Name: ${formState.name} <br/> Email: ${formState.email} <br/> Phone: ${formState.phone} <br/> <br/> <br/> Message: <br/> ${formState.message} `
  
  }
if (window.Email) {
  window.Email.send(config).then(() => alert("تم الارسال"))
}
}
  return (
    <>
       <div className='PrImg'>
       <h1 data-aos="fade-up"
     data-aos-anchor-placement="top-bottom"
     data-aos-offset="200"
     data-aos-delay="100"
     data-aos-duration="500"
     data-aos-easing="linear"><span>تواصل</span> معنا</h1>
               <img  width={"80%"}  src="https://techatok.com/wp-content/uploads/2023/03/Illustration-min-1-1.png" alt="" data-aos="fade-up"
     data-aos-anchor-placement="top-bottom"
     data-aos-offset="250"
     data-aos-delay="250"
     data-aos-duration="1000"
     data-aos-easing="linear"/>
        </div>
        <div dir="rtl" className="mainBlock">
<br/>
  <br/>
  <br/>
<div className="row block-9 justify-content-center mb-5">
<div data-aos="fade-right"
     data-aos-anchor-placement="top-bottom"
     data-aos-offset="300"
     data-aos-delay="100"
     data-aos-duration="700"
     data-aos-easing="linear" className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
<h2  style={{fontSize: "50px"}} className="text-center mb-5">لا تتردد في سؤالنا<br/>عن أي شيء</h2>
        <div className="secondlargdivof5  move2">
<div className="locasssts">
  <div className='test'>
    <span>
        <i className="fa-solid fa-location-dot"></i>
    </span>
    <p>مكة المكرمة - النسيم</p>
  </div>

</div>
<div className="locasssts">
  <div className='test'>
    <span>
        <i class="fa-brands fa-whatsapp"></i> 
    </span>
<p>
966507006849
</p>
  </div>

</div>
<div className="locasssts">
  <div className='test'>
    <span>
            <i className="fa-solid fa-envelope envoo"></i>
    </span>
   <p>contact@blumark24.com</p>
  </div>

</div>
</div>
</div>
<div class="container col-xl-6 col-lg-6 col-md-12 col-sm-12">
      <div class="text">
    اتصل بنا
      </div>
      <form onSubmit={submitHandler}>
         <div class="form-row">
            <div class="input-data">
               <input name='name' type="text" value={formState.name || ''}  required onChange={changeHandler}/>
               <div class="underline"></div>
               <label for=""> الاسم</label>
            </div>
            <div class="input-data">
               <input type="phone" name='phone' value={formState.phone || ''}  required onChange={changeHandler} />
               <div class="underline"></div>
               <label for="">رقم الجوال</label>
            </div>
         </div>
         <div class="form-row">
            <div class="input-data">
               <input name='email' type="email" value={formState.email || ''} required onChange={changeHandler}/>
               <div class="underline"></div>
               <label for="">البريد الالكتروني</label>
            </div>
            <div class="input-data">
               <input type="text" name='subject' value={formState.subject || ''}  required onChange={changeHandler} />
               <div class="underline"></div>
               <label for="">الموضوع</label>
            </div>
         </div>
         <div class="form-row">
         <div class="input-data textarea">
            <textarea rows="8" cols="80" name='message' value={formState.message || ''}   onChange={changeHandler} ></textarea>
            <br />
            <div class="underline"></div>
            <label for="">اكتب رسالتك</label>
            <br />
            <div class="form-row submit-btn">
               <div class="input-data">
                  <div class="inner"></div>
                  <input type="submit" value="ارسل الان"/>
               </div>
            </div>
            </div>
            </div>
      </form>
      </div>
</div>
</div>

    </>
  )
}

export default Contact
