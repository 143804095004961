import React, { useEffect } from 'react'
import "./AboutUs.css"
import AOS from 'aos';
import "aos/dist/aos.css"
function AboutUs() {
    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div dir='rtl'  className='container'>
     <div style={{textAlign: "center"}} className='AboutTextDiv' data-aos="fade-up"
     data-aos-offset="200"
     data-aos-delay="150"
     data-aos-duration="1200"
     data-aos-easing="ease-in-sine">
        <h1>من نحن</h1>
        <br/>
        <p>نحن في بلومارك ملتزمون بمساعدة الاعمال في المملكة العربية السعودية على تحقيق أهدافها والسعي الى تسريع التحول الرقمي . بصفتنا شركة ذات نظرة مستقبلية ، فإننا نتماشى مع رؤية المملكة 2030 للتنويع الاقتصادي والابتكار والاستدامة. يعمل فريقنا من المسوقين والمصممين والمطورين ذوي الخبرة يداً بيد لإنشاء حلول مبتكرة وفعالة تساعد عملائنا على الوصول إلى جمهورهم المستهدف وتحقيق أهداف أعمالهم. نحن نؤمن بقوة الاستراتيجيات المبنية على البيانات والتفكير الإبداعي والتحسين المستمر لتحقيق نتائج استثنائية. نحن فخورون بكوننا شركة سعودية ، وملتزمون بدعم نمو مجتمعنا ونجاحه. مهمتنا هي أن نكون شريكًا موثوقًا به للاعمال من جميع الفئات والقطاعات ، ونقدم حلولًا مخصصة تدفع وتساعد عملائنا على النمو للبقاء في الصدارة. في بلومارك نحن متحمسون لما نقوم به وملتزمون بتقديم التميز في كل ما نقوم به. ندعوكم للانضمام إلينا في رحلتنا نحو النجاح واكتشاف قوة التسويق الفعال في العصر الرقمي.</p>
   <img width={"50%"} src="\images\New folder[1]\600[1]\4 copy.png" alt=""/>
     </div>
     <div style={{textAlign: "center"}} className='AboutTextDiv' data-aos="fade-up"
     data-aos-offset="300"
     data-aos-delay="150"
     data-aos-duration="1200"
     data-aos-easing="ease-in-sine">
        <h1 >رؤيتنا</h1>
         <br/>
      <p style={{textAlign: "start"}} >1.توسيع خدماتنا: ففي السنوات الخمس المقبلة ، سنقوم بتوسيع خدماتنا لتشمل حلول تسويقة رقمية أكثر شمولاً ، مثل تسويق العملات الرقمية ، وبناء الذكاء الاصطناعي ، وإنشاء التطبيقات اللامركزية. سيتيح لنا ذلك تقديم مجموعة كاملة من الخدمات لعملائنا وأن نصبح مركزا لجميع احتياجات التسويق الرقمي الخاصة بهم.<br/>
2. بناء فريق أقوى: سنستمر في جذب أفضل الكوادر والاحتفاظ بهم في المنظومة ، والاستثمار في تدريب وتطوير أعضاء فريقنا. من خلال بناء فريق من الخبراء المتحمسين لما يفعلونه ، سنكون قادرين على تقديم نتائج استثنائية لعملائنا.<br/>
3. تطوير علاقات قوية مع العملاء: سيتم التركيز على بناء علاقات طويلة الأمد مع عملائنا ، مبنية على الثقة والشفافية والتواصل المفتوح. هدفنا هو أن نصبح شريكًا موثوقًا به لعملائنا ، ومساعدتهم على تحقيق أهداف أعمالهم من خلال استراتيجيات التسويق الرقمي الفعالة.<br/>
4. التوسع في أسواق جديدة: مع استمرارنا في النمو والتوسع في خدماتنا ، سوف نتطلع أيضًا إلى التوسع في أسواق جديدة في جميع أنحاء المملكة العربية السعودية. وسوف يكون التركيز على استهداف الشركات الصغيرة مروراً بالمتوسطة إلى الكبيرة ، ومساعدتهم على تحقيق أهدافهم التسويقية.<br/>
5. احتضان الابتكار: أخيرًا ، سنواصل احتضان الابتكار والتقنيات الجديدة في نهجنا للتسويق الرقمي. من خلال البقاء في الصدارة واعتماد أدوات وتقنيات جديدة ، سنكون قادرين على تقديم نتائج أفضل لعملائنا ، ومساعدتهم على البقاء في صدارة المنافسة.<br/>
بشكل عام ، تتمثل رؤيتنا للسنوات الخمس المقبلة في أن نصبح شركة التسويق الرقمي الرائدة في المملكة العربية السعودية ، وتقديم نتائج استثنائية لعملائنا ودفع عجلة النمو والابتكار في هذا المجال.</p>
   <img  width={"50%"} src="\images\New folder[1]\600[1]\6666666666666.png" alt=""/>
     </div>
     <div className='row Sec2'>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 Sec1Text' data-aos="fade-left"
     data-aos-offset="300"
     data-aos-delay="100"
     data-aos-duration="1200"
     data-aos-easing="ease-in-sine">
<h1>
مهمتنا
</h1>
<h4>مهمتنا في بلومارك بالمملكة العربية السعودية هي تقديم حلول تسويقية مبتكرة وفعالة تمكن عملائنا من تحقيق أهداف أعمالهم. نحن نسعى جاهدين لتقديم خدمة عملاء استثنائية ، وبناء علاقات قوية ، والاستفادة من أحدث التقنيات والآليات لزيادة عوائد الاستثمار للعملاء.فريقنا من المهنيين ذوي الخبرة ملتزمون بوضع استراتيجيات مخصصة تتعامل مع التحديات والفرص الفريدة في السوق السعودي ، مع الحفاظ على أعلى معايير السلوك الأخلاقي والمهني. نهدف إلى أن نكون شريكًا موثوقًا به و شركة تسويق رائدة في المملكة العربية السعودية و نقدم نتائج قابلة للقياس وتتجاوز توقعات عملائنا</h4>

</div>
<div className='col-xl-6 col-lg-6 col-md-12 col-sm-12' data-aos="fade-right"
     data-aos-offset="300"
     data-aos-delay="100"
     data-aos-duration="1200"
     data-aos-easing="ease-in-sine">
<img width={"82%"} src="\images\New folder[1]\600[1]\6 copy.png" alt=""/> 
</div>
</div>
<br/>
<br/>
<br/>
<div className='row'>
<div className='titleDiv' data-aos="fade-up"
     data-aos-offset="200"
     data-aos-delay="150"
     data-aos-duration="1200"
     data-aos-easing="ease-in-sine">
<h3>فريق بلومارك</h3>
<h1>خطوات عمل فريق بلومارك</h1>
</div>
  <div className='workbox col-xl-4 col-lg-4 col-md-6 col-sm-12'>
    <img src="https://techatok.com/wp-content/uploads/2023/03/start-up-1-min.png" alt=""/>
    <h4>الدراسة و التحليل</h4>
  </div>
  <div className='workbox col-xl-4 col-lg-4 col-md-6 col-sm-12'>
    <img src="https://techatok.com/wp-content/uploads/2023/03/start-up-1.png" alt=""/>
    <h4>العمل على مشروعك</h4>
  </div>
  <div className='workbox col-xl-4 col-lg-4 col-md-6 col-sm-12'>
    <img src="https://techatok.com/wp-content/uploads/2023/03/start-up-1-1.png" alt=""/>
    <h4>معرفة فئاتك المستهدفة</h4>
  </div>
  <div className='workbox col-xl-4 col-lg-4 col-md-6 col-sm-12'>
    <img src="https://techatok.com/wp-content/uploads/2023/03/start-up-1-2.png" alt=""/>
    <h4>كتابة محتوى وتصميم مرئى إبداعي</h4>
  </div>
  <div className='workbox col-xl-4 col-lg-4 col-md-6 col-sm-12'>
    <img src="https://techatok.com/wp-content/uploads/2023/03/start-up-1-3.png" alt=""/>
    <h4>ربطك مع المؤثرين</h4>
  </div>
  <div className='workbox col-xl-4 col-lg-4 col-md-6 col-sm-12'>
    <img src="https://techatok.com/wp-content/uploads/2023/03/start-up-1-4.png" alt=""/>
    <h4>تطوير مشروعك ودعمه</h4>
  </div>
</div>
<br/>
<br/>
<br/>
<br/>
<div className='row ProgresDiv'>
  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' >
    <h1>تجربة بلومارك خير برهان</h1>
    <br/>
    <p>بتجربتك الرائعة مع بلومارك ستحقق اهدافك بشكل مذهل وبجودة عاليه، فريقنا سيضمن لك التفوق في مجالك التجاري والتفرد بمنتجاتك وتحسين دخلك بشكل مستمر</p>
             <div className="skill-box">
                <span className="title">Development</span>
                <div className="skill-bar">
                    <span className="skill-per html">
                        <span className="TipCom">90%</span>
                    </span>
                </div>
            </div>
            <div className="skill-box">
                <span className="title">Optimization</span>
                <div className="skill-bar">
                    <span className="skill-per css">
                        <span className="TipCom">80%</span>
                    </span>
                </div>
            </div>
            <div className="skill-box">
                <span className="title">Advertising</span>
                <div className="skill-bar">
                    <span className="skill-per javascript">
                        <span className="TipCom">65%</span>
                    </span>
                </div>
            </div>
  </div>
  <img className='col-xl-6 col-lg-6 col-md-6 col-sm-12'src="\images\New folder[1]\600[1]\7 copy.png" alt="" data-aos="fade-up"
     data-aos-offset="200"
     data-aos-delay="150"
     data-aos-duration="1200"
     data-aos-easing="ease-in-sine" />
</div>
   
        </div>
  )
}

export default AboutUs