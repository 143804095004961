import React from 'react'
import { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css"
import "./ECommerce.css"
function Privacy() {
    useEffect(()=>{
        AOS.init();
    }, [])
  return (
    <div dir='rtl' className='container'>
         <div style={{textAlign: "center"}} className='AboutTextDiv'>
       <h1 data-aos="fade-right"
     data-aos-offset="100"
     data-aos-delay="100"
     data-aos-duration="700"
     data-aos-easing="ease-in-sine">سياسة الخصوصية</h1>
        <br/>
         <img width={"40%"} src="\images\New folder[1]\600[1]\1 copy.png" alt="" data-aos="fade-up"
     data-aos-offset="200"
     data-aos-delay="150"
     data-aos-duration="1200"
     data-aos-easing="ease-in-sine" />
      <p data-aos="fade-up"
     data-aos-offset="200"
     data-aos-delay="150"
     data-aos-duration="1200"
     data-aos-easing="ease-in-sine" style={{textAlign: "start" , lineHeight: "1.9em"}}>توضح سياسة الخصوصية هذه كيف تضمن وكالة التسويق وتطوير البرامج التابعة لنا والتي تعمل في المملكة العربية السعودية خصوصية وأمن المعلومات الشخصية التي يتم جمعها من عملائنا وزوار موقعنا. نحن ملتزمون بحماية خصوصيتك والحفاظ على سرية معلوماتك الشخصية. تشرح هذه السياسة كيف نجمع المعلومات ونستخدمها ونكشف عنها ، وكذلك كيف يمكنك الوصول إلى معلوماتك والتحكم فيها. المعلومات التي نجمعها نقوم بجمع المعلومات الشخصية من عملائنا وزوار الموقع ، بما في ذلك الأسماء وعناوين البريد الإلكتروني وأرقام الهواتف ومعلومات الشركة. قد نقوم أيضًا بجمع معلومات غير شخصية مثل نوع المتصفح وعنوان IP وبيانات استخدام موقع الويب. <br/>
     كيف نستخدم معلوماتك نستخدم معلوماتك الشخصية لتزويدك بالخدمات التي طلبتها وللتواصل معك ولتحسين خدماتنا. قد نستخدم معلوماتك أيضًا لإرسال مواد تسويقية إليك أو لإجراء أبحاث السوق.<br/>
     إفشاء المعلومات الخاصة بك نحن لا نكشف عن معلوماتك الشخصية لأطراف ثالثة ، باستثناء ما يقتضيه القانون أو حسب الضرورة لتزويدك بخدماتنا. يجوز لنا استخدام موفري خدمات تابعين لجهات خارجية لمساعدتنا في إدارة أعمالنا أو تقديم خدمات لك ، وقد نشارك معلوماتك معهم عند الضرورة.<br/>
     أمن المعلومات الخاصة بك نتخذ خطوات معقولة لحماية معلوماتك الشخصية من الوصول أو الكشف أو التغيير غير المصرح به.
     نحن نستخدم تدابير أمنية متوافقة مع معايير الصناعة ، مثل جدران الحماية والتشفير ، لحماية معلوماتك. ومع ذلك ، لا توجد وسيلة نقل عبر الإنترنت أو التخزين الإلكتروني آمنة بنسبة 100٪ ، ولا يمكننا ضمان الأمان المطلق لمعلوماتك.<br/>
     الوصول إلى المعلومات الخاصة بك والتحكم فيها لديك الحق في الوصول إلى معلوماتك الشخصية والتحكم فيها.<br/>
     يمكنك طلب الوصول إلى معلوماتك ، أو طلب إجراء تصحيحات ، أو طلب حذف معلوماتك من سجلاتنا.<br/>
     للقيام بذلك ، يرجى الاتصال بنا باستخدام معلومات الاتصال الواردة أدناه.<br/>
     التغييرات على هذه السياسة قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر لتعكس التغييرات في ممارساتنا أو لأسباب تشغيلية أو قانونية أو تنظيمية أخرى.<br/>
     سنخطرك بأي تغييرات تطرأ على هذه السياسة عن طريق نشر السياسة المحدثة على موقعنا الإلكتروني وتقديم إشعار لك وفقًا لما يقتضيه القانون. اتصل بنا إذا كانت لديك أي أسئلة حول سياسة الخصوصية الخاصة بنا أو كيفية تعاملنا مع معلوماتك الشخصية ، فيرجى الاتصال بنا على [info@blumark24.com]. <br/></p>  
     </div>
    </div>
  )
}

export default Privacy